<template>
<div class="container">

    <div class="row g-4 mb-3" id="cards">

        <div class="col-6 col-md-4">
            <div class="card h-100">
                <img src="../../assets/osnovne-informacije.png" class="card-img-top" alt="Osnovne informacije">
                <div class="card-body">
                    <h4 class="card-title">Osnovne informacije</h4>
                </div>
                <router-link class="stretched-link" :to="{ name: 'EmployeesInfoPage'}"></router-link>
            </div>
        </div>

        <div class="col-6 col-md-4">
            <div class="card h-100 karnet">
                <img src="../../assets/karnet.png" class="card-img-top" alt="Karnet">
                <div class="card-body">
                    <h4 class="card-title">Karnet</h4>
                </div>
                <router-link class="stretched-link" :to="{ name: 'EmployeesCarnet'}"></router-link>
            </div>
        </div>
        
        <div class="col-6 col-md-4">
            <div class="card h-100 upucivanje">
                <img src="../../assets/upucivanje-radnika.png" class="card-img-top" alt="Upućivanje radnika">
                <div class="card-body">
                    <h4 class="card-title">Upućivanje radnika</h4>
                </div>
                <router-link class="stretched-link" :to="{ name: 'EmployeesAssignment'}"></router-link>
            </div>
        </div>
        
        <div class="col-6 col-md-4">
            <div class="card h-100 upucivanje">
                <img src="../../assets/bus.png" class="card-img-top" alt="Upućivanje radnika">
                <div class="card-body">
                    <h4 class="card-title">Dodeljivanje vozila</h4>
                </div>
                <router-link class="stretched-link" :to="{ name: 'EmployeesTimelineTrip'}"></router-link>
            </div>
        </div>
        
        <div class="col-6 col-md-4 position-relative">
            <span v-if="absenceRequests && absenceRequests.length > 0" class="position-absolute translate-middle badge rounded-pill bg-danger request-badge">{{absenceRequests.length}}</span>
            <div class="card h-100 upucivanje">
                <img src="../../assets/abs_calendar.png" class="card-img-top" alt="Upućivanje radnika">
                <div class="card-body">
                    <h4 class="card-title">Odsustva</h4>
                </div>
                <router-link class="stretched-link" :to="{ name: 'AbsenceRequests'}"></router-link>
            </div>
        </div>
        
    </div>
</div>
</template>

<script>

    import {mapState} from 'vuex'


    export default {
        computed: {
            ...mapState([
                'absenceRequests'
            ]),
        },

        methods: {
            loadRequests() {
                this.$store.dispatch('loadAbsenceRequests');
            }
        },

        created() {
            this.loadRequests();
        }
    }
</script>




<style scoped>
.request-badge {
    font-size: 2.5em;
    z-index: 1000;
    left: 95%;
    top: 10px;
}

.container {
    min-height: 73vh;
}

#cards {
    margin-top: 30px;
}

.h4 {
    font-style: bold;
}

.card-img-top {
    width: 100%;
    height: 16vw;
    min-height: 150px;
    object-fit: cover;
}

#cards .card {
    min-height: 150px;
}

#cards .card-body {
    background: #ffffffa7;
    position: absolute;
    bottom: 0;
    width: 100%;
}

#cards .card:hover .card-body {
    background: #ffffffef;
    position: absolute;
    bottom: 0;
    width: 100%;
}

/* .karnet {
    padding-left: 35px;
}


.upucivanje {
    padding-left: 35px;
} */

</style>